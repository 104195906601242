/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { format } from "date-fns";

import { OpaqueCard } from "@components/StyledComponents";
import CollapsibleSmartTable from "@components/Table/CollapsibleSmartTable";
import type {
  Column,
  PaginatedResourceProps,
} from "@components/Table/CollapsibleSmartTable";
import { PurchaseOrder } from "@models";
import { useApiResource } from "@services/api";
import {
  formatMoneyString,
  upCase,
  utcToLocalDate,
} from "@utility/utilityFunctions";

import PurchaseOrderHistoryVariantTable from "./PurchaseOrderHistoryVariantTable";
import { calcTotalCostPurchaseOrder } from "./purchasingHelpers";

const purchaseOrderHistoryTableColumns = (
  role: string,
  hasPurchasingAgents: boolean
): Column<PurchaseOrder>[] => [
  { id: "id", label: "Purchase Order #" },
  ...(hasPurchasingAgents
    ? [{ id: "purchasingAgentReferenceNumber", label: "External PO #" }]
    : []),
  { id: "type", label: "Type", render: (value) => upCase(value, "-") },
  {
    id: "purchaser.name",
    label: "Purchased By",
  },
  role === "supplier"
    ? {
        id: "organization.name",
        label: "Organization",
      }
    : {
        id: "supplier.name",
        label: "Supplier",
      },
  {
    id: "invoiceNumber",
    label: "Invoice",
  },
  {
    id: "supplierReference",
    label: "Supplier Reference",
  },
  {
    id: "total",
    label: "Total Cost",
    align: "right",
    render: (v, purchaseOrder) =>
      formatMoneyString(calcTotalCostPurchaseOrder(purchaseOrder)),
  },
  {
    id: "status",
    label: "Status",
    render: (status, purchaseOrder) => (
      <div>
        {upCase(status, "-")}
        {purchaseOrder.submittedAt && (
          <div tw="text-gray-400">
            {format(utcToLocalDate(purchaseOrder.submittedAt), "MM/dd/yyyy")}
          </div>
        )}
      </div>
    ),
  },
];

const PurchaseOrderHistoryTable = ({
  rows,
  ...tableProps
}: PaginatedResourceProps & {
  rows: PurchaseOrder[];
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: groupCategories = [] } = useApiResource("group-categories");

  const handleRowClick = (id) => {
    navigate(`/purchase-orders/history/${id}`, {
      state: { search: location.search },
    });
  };

  const {
    role,
    organization: { hasPurchasingAgents },
  } = useSelector((state: any) => state.currentUser);

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <CollapsibleSmartTable
        rows={rows}
        columns={purchaseOrderHistoryTableColumns(role, hasPurchasingAgents)}
        onRowClick={(row) => handleRowClick(row.id)}
        collapsibleContent={(row) => {
          return (
            row.purchaseOrderVariants?.length > 0 && (
              <PurchaseOrderHistoryVariantTable
                rows={row.purchaseOrderVariants}
                groupCategories={groupCategories}
              />
            )
          );
        }}
        {...tableProps}
      />
    </OpaqueCard>
  );
};

export default PurchaseOrderHistoryTable;
