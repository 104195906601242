/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";

import { useApiResource } from "@services/api";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledFilterAutocomplete from "./ControlledFilterAutocomplete";

const useGroups = (id) => {
  const res = useApiResource("groups", {
    filter: { "group-category-id": id },
    revalidate: false,
  });
  return {
    ...res,
    groups: res.data ?? [],
  };
};
const GroupPopper = ({ category }) => {
  const { groups, isLoading } = useGroups(category.id);
  const { control, setValue, getValues } = useFilterContext();

  useEffect(() => {
    const groupValue = getValues("groups");
    if (!groupValue || Array.isArray(groupValue)) {
      setValue("groups", {});
    }
  }, [getValues, setValue]);

  return (
    <ControlledFilterAutocomplete
      name={`groups.${category.id}`}
      control={control}
      options={groups}
      inputPlaceholder={`Filter ${category.name}`}
      isLoading={isLoading}
    />
  );
};

const GroupTile = ({ category, ...props }: any) => {
  const { groups } = useGroups(category.id);
  const { filterValues } = useFilterContext();
  const groupsParam = filterValues.groups?.[category.id] || [];
  const selectedGroups = groups.filter((g) => groupsParam.includes(g.id));

  if (selectedGroups.length === 0) return null;

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">{category.name} is&nbsp;</span>
      <span tw="text-neutral-800 font-medium">
        {[...selectedGroups]
          .splice(0, 2)
          .map((g) => g.name)
          .join(", ")}
        {selectedGroups.length > 2 && (
          <span>, + {selectedGroups.length - 2}</span>
        )}
      </span>
    </Chip>
  );
};

const GroupsFilter = {
  Tile: GroupTile,
  Popper: GroupPopper,
};

export default GroupsFilter;
