import React from "react";

import CancelIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import PropTypes from "prop-types";

import { OpaqueCard } from "../../StyledComponents";
import SmartTable from "../../Table/SmartTable";

const AddressTable = ({
  addresses,
  updateFaveListAddresses,
  isValidating,
  isLoading,
}) => {
  const handleDelete = async (addId) => {
    const newAddressArray = addresses.addresses.filter((a) => {
      return a.id !== addId;
    });
    updateFaveListAddresses(addresses.id, newAddressArray);
  };

  return (
    <>
      <OpaqueCard tw="p-0 overflow-hidden relative">
        <SmartTable
          rows={addresses.addresses}
          isValidating={isValidating}
          isLoading={isLoading}
          columns={[
            { id: "name", label: "Name", sort: "name" },
            { id: "streetAddress1", label: "Street Address One" },
            { id: "city", label: "City" },
            {
              id: "state.code",
              label: "State/Region",
              render: (stateCode, row) => stateCode ?? row.region,
            },
            { id: "zip", label: "ZIP" },
            { id: "phoneNumber", label: "Phone Number" },
            {
              id: "remove",
              label: "Remove Favorite",
              render: (_, address) => (
                <Tooltip title="Remove Favorite">
                  <IconButton
                    onClick={() => handleDelete(address.id)}
                    size="large"
                  >
                    <CancelIcon fontSize="small" color="inherit" />
                  </IconButton>
                </Tooltip>
              ),
            },
          ]}
        />
      </OpaqueCard>
    </>
  );
};

AddressTable.propTypes = {
  isValidating: PropTypes.bool.isRequired,
  addresses: PropTypes.object.isRequired,
};

export default React.memo(AddressTable);
