/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import { OrderUploadTaxAndFreight } from "@features/orders";
import { PurchaseOrderHistoryTable } from "@features/purchaseOrders";
import { RequestReportButton, useReport } from "@features/reports";
import { useApiResourcePaginated } from "@services/api";
import DocTitle from "@utility/DocTitle";
import useRoleIs from "@utils/useRoleIs";

const PurchaseOrderHistory = () => {
  const [filters] = useFilterParams();
  const roleIs = useRoleIs();
  const { allowShippingCostsAtOrderLevel } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const params = {
    filter: {
      searchTerm: filters.q,
      type: filters.orderType,
      groupIds: filters.groups && Object.values(filters.groups).flat(),
      programIds: filters.programs,
      supplierIds: filters.suppliers,
      ...(filters.purchaseOrderStatus && {
        status: filters.purchaseOrderStatus,
      }),
      purchaserIds: filters.purchasers,
    },
    sort: filters.sort,
  };

  const { data, ...tableProps } = useApiResourcePaginated(`purchase-orders`, {
    params: {
      ...params,
    },
    keepPreviousData: true,
    revalidateOnFocus: false,
  });

  useReport("purchase-order-history", ["purchase-orders", params]);

  return (
    <>
      <DocTitle title={"Purchase Order History"} />
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap justify-between items-center">
          <h2 tw="text-2xl text-neutral-600 font-bold mb-2">
            Purchase Order History
          </h2>

          <div tw="flex items-center space-x-2">
            <RequestReportButton reportName={`purchase-order-history`} />
            {roleIs("admin", "super", "purchasing-agent") &&
              allowShippingCostsAtOrderLevel && <OrderUploadTaxAndFreight />}
          </div>
        </div>
        <Filters
          searchTitle="Search..."
          slots={[
            "orderType",
            "groups",
            "programs",
            "suppliers",
            "purchaseOrderStatus",
            "purchasers",
          ]}
          defaultValues={{
            purchaseOrderStatus: roleIs("purchasing-agent")
              ? ["ready-to-purchase"]
              : [],
          }}
        />

        <PurchaseOrderHistoryTable rows={data ?? []} {...tableProps} />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default PurchaseOrderHistory;
