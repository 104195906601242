/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";

import { AddressSearch } from "@features/addresses";

import { useInput } from "../../../hooks/inputs/useInput";
import MoreOptions from "../../Utility/OptionsMenu";
import AddressTable from "./AddressTable";

const MemoNameInput = ({ addressList, updateSWR, currentTerritoryId }) => {
  const {
    value: name,
    bind: bindName,
    setChange: setNameChange,
    change: nameChange,
  } = useInput(addressList.name);

  const handleUpdateName = async (newName, faveListId) => {
    await updateSWR({
      type: "address-favorite-sets",
      id: faveListId,
      name: newName,
      relationshipNames: ["addresses", "territory"],
      addresses: addressList.addresses.map((a) => {
        return {
          type: "address",
          id: a.id,
        };
      }),
      territory: { id: currentTerritoryId, type: "territory" },
    });
  };

  return (
    <TextField
      style={{ width: "45%" }}
      size="small"
      variant="outlined"
      color="secondary"
      name="listName"
      type="text"
      label="List Name"
      value={name}
      {...bindName}
      onBlur={async () => {
        if (nameChange) {
          handleUpdateName(name, addressList.id);
          setNameChange(false);
        }
      }}
    />
  );
};

const FavoriteAddressList = ({
  addressList,
  updateFaveListAddresses,
  updateSWR,
  removeSWR,
  currentTerritoryId,
  isValidating,
  isLoading,
}) => {
  const [reset, setReset] = useState(false);

  const handleUpdate = async (value, faveListId) => {
    const newAddressArray = [...addressList.addresses, ...value];
    updateFaveListAddresses(faveListId, newAddressArray);
  };

  const handleDelete = async (faveListId) => {
    await removeSWR({ id: faveListId });
  };

  return (
    <div>
      <div tw="flex items-center gap-2 mb-4">
        <MemoNameInput
          addressList={addressList}
          updateSWR={updateSWR}
          currentTerritoryId={currentTerritoryId}
        />
        <AddressSearch
          multiple
          validateTerritory
          renderTags={() => null}
          reset={reset}
          setReset={setReset}
          getOptionDisabled={(option) =>
            addressList.addresses.some((a) => a.id === option.id)
          }
          value={addressList.addresses}
          onChange={(e) => {
            handleUpdate(e, addressList.id);
          }}
        />
        <MoreOptions tw="text-2xl ml-auto flex-none">
          <MenuItem
            onClick={() => {
              handleDelete(addressList.id);
            }}
          >
            Delete List
          </MenuItem>
        </MoreOptions>
      </div>
      <AddressTable
        addresses={addressList}
        name={addressList.name}
        id={addressList.id}
        handleUpdate={handleUpdate}
        updateFaveListAddresses={updateFaveListAddresses}
        isValidating={isValidating}
        isLoading={isLoading}
      />
    </div>
  );
};

export default FavoriteAddressList;
