/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { ReactNode, useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useNavigate } from "react-router-dom";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  CircularProgress,
  Collapse,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";

import { get } from "lodash";

import { ResourceError } from "@features/errors";
import { useFilterContext, useFilterParams } from "@features/filters";

export type Column<R> = {
  id: string;
  label: ReactNode;
  align?: "left" | "right" | "center";
  sort?: string;
  render?: (cell: any, row: R) => ReactNode;
  renderText?: (cell: any, row: R) => string;
  to?: (row: R) => string;
};

export type PaginatedResourceProps = {
  isLoading?: boolean;
  isValidating?: boolean;
  isLastPage?: boolean;
  error: any;
  nextPage?: () => void;
};

export type SmartTableProps<R> = PaginatedResourceProps & {
  rows: R[];
  columns: Column<R>[];
  onRowClick?: (row: R) => void;
  collapsibleContent?: (row: R) => ReactNode;
};

const splitSort = (str: string): ["asc" | "desc", string] =>
  str.startsWith("-") ? ["desc", str.slice(1)] : ["asc", str];

const TableHeader = ({ columns, hasCollpsibleContent }) => {
  const { setValue, getValues } = useFilterContext();
  const [{ sort: sortParam }, applyFilters] = useFilterParams();
  const [sortDirection, sortValue] = splitSort(sortParam ?? "");

  const handleSort = (value) => {
    if (sortValue === value) {
      const switchSortDirection = sortDirection === "desc" ? "" : "-";
      setValue("sort", switchSortDirection + value);
    } else {
      setValue("sort", "-" + value);
    }
    applyFilters(getValues());
  };

  return (
    <TableHead
      css={{
        "&": tw`relative z-10`,
        th: tw`py-3 text-xs tracking-wider uppercase whitespace-nowrap text-neutral-500`,
      }}
    >
      <TableRow>
        {hasCollpsibleContent && <TableCell />}
        {columns.map(({ id, align, label, sort }) => (
          <TableCell key={id} align={align}>
            {sort ? (
              <TableSortLabel
                tw="w-full -mr-4"
                active={sort === sortValue}
                direction={(sort === sortValue && sortDirection) || "desc"}
                onClick={() => handleSort(sort)}
              >
                {label}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const SmartTableRow = ({ columns, onRowClick, collapsibleContent, row }) => {
  const [open, setOpen] = useState(false);
  const [renderContent, setRenderContent] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (url: string) => (e) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url, { state: { query: window.location.search } });
    }
  };
  return (
    <>
      <TableRow
        css={[tw`hover:bg-neutral-50`, onRowClick && tw`cursor-pointer`]}
        onClick={() => onRowClick?.(row)}
      >
        {collapsibleContent && (
          <TableCell tw="align-middle">
            <IconButton
              size="small"
              edge="end"
              tw="text-neutral-500 -my-3"
              onClick={(e) => {
                e.stopPropagation();
                setOpen((prev) => !prev);
                if (!renderContent) setRenderContent(true);
              }}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((col) => (
          <TableCell
            key={col.id}
            align={col.align ?? "left"}
            onClick={col.to && handleNavigate(col.to?.(row))}
            css={col.to && tw`cursor-pointer`}
          >
            {col.render ? col.render(get(row, col.id), row) : get(row, col.id)}
          </TableCell>
        ))}
      </TableRow>
      {collapsibleContent && renderContent && (
        <TableRow tw="box-content">
          <TableCell tw="p-0 border-0" colSpan={columns.length + 1}>
            <Collapse in={open} timeout="auto" tw="overflow-hidden w-full">
              <div tw="bg-neutral-100 py-4 px-6 shadow-inner border-b">
                {collapsibleContent(row)}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const CollapsibleSmartTable = <R extends any>({
  rows,
  isLoading,
  isValidating,
  isLastPage,
  error,
  nextPage,
  columns,
  onRowClick,
  collapsibleContent,
}: SmartTableProps<R>) => {
  const fetchNext = () =>
    !isLoading && !isValidating && !isLastPage && nextPage?.();
  const scrollRef = useBottomScrollListener(fetchNext, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  }) as any;

  useEffect(() => {
    if (isLoading || !scrollRef.current) return;
    scrollRef.current.scrollTop = 0;
  }, [isLoading, scrollRef]);

  if (isLoading && rows.length === 0) return <CircularProgress tw="m-6" />;

  return (
    <div tw="relative h-full overflow-hidden">
      <TableContainer
        css={[
          tw`max-h-full`,
          isLoading && tw`transition-opacity opacity-50 pointer-events-none`,
        ]}
        ref={scrollRef}
      >
        <Table stickyHeader css={{ td: tw`align-top` }}>
          <TableHeader
            columns={columns}
            hasCollpsibleContent={Boolean(collapsibleContent)}
          />
          <TableBody>
            {!isLoading && rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <Typography>No results match your search...</Typography>
                </TableCell>
              </TableRow>
            )}
            {rows.map((row, i) => (
              <SmartTableRow
                key={i}
                columns={columns}
                onRowClick={onRowClick}
                collapsibleContent={collapsibleContent}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div tw="h-1 w-full absolute left-0 bottom-0">
        {isValidating && !isLoading && <LinearProgress />}
      </div>
      {error && (
        <div tw="absolute inset-0 bg-white/50 backdrop-blur-sm p-4 pt-16">
          <ResourceError error={error} />
        </div>
      )}
    </div>
  );
};

export default CollapsibleSmartTable;
