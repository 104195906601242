/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import { AddToPhotosRounded } from "@mui/icons-material";
import { Chip, IconButton, Tooltip } from "@mui/material";

import { useApiResource } from "@services/api";
import { ControlledAutocompleteInput as AutocompleteInput } from "@utils/forms";

import BlockCard from "../../../ui/BlockCard";

const Loading = tw.div`h-10 rounded-md bg-neutral-100 animate-pulse`;
const SelectTerritories = () => {
  const { data: allTerritories, isLoading } = useApiResource("territories");
  const { control, setValue } = useFormContext();
  const territories = useWatch({ name: "territories" });

  if (!allTerritories || isLoading) return <Loading />;

  const territoryOptions = allTerritories.map(({ id, name }) => ({
    id,
    name,
  }));

  const handleRemoveTerritory = (id) => {
    setValue(
      "territories",
      territories.filter((terr) => terr.id !== id),
      { shouldDirty: true }
    );
  };

  return (
    <div tw="space-y-3">
      <div tw="flex gap-2">
        <AutocompleteInput
          tw="flex-1"
          multiple
          label="Territories *"
          name="territories"
          control={control}
          options={territoryOptions}
          renderTags={() => null}
          rules={{ required: true }}
        />
        <Tooltip title="Add all territories">
          <IconButton
            onClick={() =>
              setValue("territories", territoryOptions, {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
          >
            <AddToPhotosRounded />
          </IconButton>
        </Tooltip>
      </div>
      <div tw="flex flex-wrap gap-2">
        {territories?.map((territory) => (
          <Chip
            size="small"
            key={territory.id}
            label={territory.name}
            onDelete={() => handleRemoveTerritory(territory.id)}
          />
        ))}
        {territories.length === 0 && (
          <div tw="text-sm text-neutral-700">No Territories selected</div>
        )}
      </div>
    </div>
  );
};

const SelectChannels = () => {
  const { data: allChannels, isLoading } = useApiResource("channels", {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });
  const { control, watch, setValue } = useFormContext();
  const channels = watch("channels");

  if (!allChannels || isLoading) return <Loading />;

  const channelOptions = allChannels.map(({ id, name }) => ({
    id,
    name,
  }));

  const handleRemoveChannel = (id) => {
    setValue(
      "channels",
      channels.filter((channel) => channel.id !== id),
      { shouldDirty: true, shouldValidate: true }
    );
  };

  return (
    <div tw="space-y-4">
      <AutocompleteInput
        multiple
        label="Channels"
        name="channels"
        control={control}
        options={channelOptions}
        renderTags={() => null}
        rules={{ required: true }}
      />
      <div tw="flex flex-wrap gap-2">
        {channels?.map((channel) => (
          <Chip
            size="small"
            key={channel.id}
            label={channel.name}
            onDelete={() => handleRemoveChannel(channel.id)}
          />
        ))}
        {channels.length === 0 && (
          <div tw="text-sm text-neutral-700">No Channels selected</div>
        )}
      </div>
    </div>
  );
};

const VisibilityBlock = () => {
  const {
    organization: { usesChannels },
  } = useSelector((state: any) => state.currentUser);

  return (
    <BlockCard title="Visibility" tw="space-y-6">
      {usesChannels && <SelectChannels />}
      <SelectTerritories />
    </BlockCard>
  );
};

export default VisibilityBlock;
