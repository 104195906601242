import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { Quote } from "@models/Quote";
import client, { RequestParams } from "@services/api";
import { QueryOptions, buildPaginatedQuery } from "@utils/reactQuery";

export const quotesKeyFactory = createQueryKeys("quotes", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () => client.get<Quote>(`quotes/${id}`).then((res) => res.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Quote[]>("quotes", { params }).then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Quote[]>("quotes", {
        params,
      }),
  }),
});

export const useQuoteQuery = (id: string | null | undefined) => {
  return useQuery({
    ...quotesKeyFactory.detail(id!),
    enabled: !!id,
    staleTime: 60_1000,
  });
};

export const useQuoteListQuery = (
  params: RequestParams,
  options?: QueryOptions<Quote[]>
) => {
  return useQuery({
    ...quotesKeyFactory.list({ skipPagination: true, ...params }),
    staleTime: 60_1000,
    ...options,
  });
};

export const usePaginatedQuoteQuery = buildPaginatedQuery(
  quotesKeyFactory.paginated,
  {
    staleTime: 60_1000,
    placeholderData: keepPreviousData,
  }
);
