import { useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import client, { useMutateError } from "@services/api";

import { usersKeyFactory } from "./userQueries";

type SupplierUser = {
  id: string;
  email: string;
  name: string;
};

export const supplierUsersKeyFactory = createQueryKeys("users", {
  list: (email: string) => ({
    queryKey: [email],
    queryFn: () =>
      client
        .get<SupplierUser[]>(`supplier-users`, {
          params: { filter: { email } },
        })
        .then((res) => res.data),
  }),
});

export const useSupplierUserListQuery = (email: string) => {
  return useQuery({
    ...supplierUsersKeyFactory.list(email),
    enabled: !!email,
    staleTime: 60_1000,
  });
};

export const useAddSupplierUserMutation = () => {
  const queryClient = useQueryClient();
  const mutateError = useMutateError();
  const orgId = useSelector((state: any) => state.currentUser.organization.id);
  return useMutation({
    mutationFn: (id: string) =>
      client
        .post<SupplierUser>(`supplier-users`, {
          userId: id,
          organizationId: orgId,
        })
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: usersKeyFactory.paginated._def,
      });
    },
    onError: (error) => mutateError(error, "Add supplier user"),
  });
};
