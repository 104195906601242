/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import { Chip } from "@mui/material";

import { pick } from "lodash/fp";

import { useApiResource } from "../../../services/api";
import {
  ControlledAutocompleteInput,
  ControlledCheckboxInput,
} from "../../../utils/forms";
import BlockCard from "../../ui/BlockCard";

const Loading = tw.div`h-10 rounded-md bg-neutral-100 animate-pulse`;
const SelectTerritories = () => {
  const { data: allTerritories, isLoading } = useApiResource("territories");
  const {
    control,
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  const territories = useWatch({ name: "territories" });
  const isTerritoryExclusive = useWatch({ name: "isTerritoryExclusive" });

  const territoryOptions = useMemo(
    () => allTerritories?.map(pick(["id", "name"])) ?? [],
    [allTerritories]
  );

  const handleRemoveTerritory = (id: string) => {
    setValue(
      "territories",
      territories.filter((terr) => terr.id !== id),
      { shouldDirty: true }
    );
  };

  useEffect(() => {
    if (!isTerritoryExclusive && territoryOptions.length > 0) {
      setValue("territories", territoryOptions);
    }
  }, [isTerritoryExclusive, territoryOptions, setValue]);

  if (!allTerritories || isLoading) return <Loading />;

  return (
    <div tw="space-y-3">
      <ControlledCheckboxInput
        control={control}
        name="isTerritoryExclusive"
        label="Is Territory Exclusive?"
      />
      {!isTerritoryExclusive && (
        <div tw="mt-0! text-neutral-600 text-lg font-light">
          Available on all territories
        </div>
      )}
      {isTerritoryExclusive && (
        <>
          <ControlledAutocompleteInput
            multiple
            label="Territories"
            name="territories"
            control={control}
            options={territoryOptions}
            renderTags={() => null}
            disableCloseOnSelect
          />
          <div tw="flex flex-wrap gap-2">
            {territories?.map((territory) => (
              <Chip
                size="small"
                key={territory.id}
                label={territory.name}
                onDelete={() => handleRemoveTerritory(territory.id)}
              />
            ))}
            {territories.length === 0 && (
              <div tw="text-sm text-neutral-700">No Territories selected</div>
            )}
          </div>
          {dirtyFields.territories && (
            <p tw="text-lg font-light text-neutral-600">
              Modifying the territories may remove order windows from this
              program if they don't share territories.
            </p>
          )}
        </>
      )}
    </div>
  );
};

const SelectChannel = ({ handleChangeChannel }) => {
  const { control } = useFormContext();
  const { data: channels } = useApiResource("channels");

  if (!channels) return <Loading />;

  return (
    <ControlledAutocompleteInput
      label="Channel"
      disableClearable
      options={channels}
      control={control}
      name="channelId"
      tw="w-full"
      onChange={handleChangeChannel}
    />
  );
};

const Visibility = ({ handleChangeChannel }) => {
  const { currentChannelId } = useSelector((state: any) => state.currentUser);

  return (
    <BlockCard title="Visibility" tw="space-y-3">
      <SelectTerritories />
      {currentChannelId && (
        <SelectChannel handleChangeChannel={handleChangeChannel} />
      )}
    </BlockCard>
  );
};

export default Visibility;
