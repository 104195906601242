import {
  formatMoney,
  handleBudgetAssignment,
  handleOrderGrandTotal,
  upCase,
} from "./utilityFunctions";

/*
These are a collection of functions that build headers, and map data for the various
pages that generate their own reports. These reports are generated a little differently
than the normal reports, and instead of getting a large dump of data, the ui loops over
each page of data from the api based on the users current filters.
*/

export const addressData = (report, addressBookType) => {
  const headers = [
    { key: "user", label: "User Name" },
    { key: "territory", label: "Territory" },
    { key: "name", label: "Name" },
    { key: "addressOne", label: "Street Address One" },
    { key: "addressTwo", label: "Street Address Two" },
    { key: "defaultAttn", label: "Attention" },
    { key: "city", label: "City" },
    { key: "state", label: "State / Region" },
    { key: "zip", label: "Zip" },
    { key: "phoneNumber", label: "Phone Number" },
    { key: "countryName", label: "Country" },
  ].filter((h) => {
    if (addressBookType !== "user" && h.key === "user") return false;
    return true;
  });

  const mappedReport = report.map((row) => ({
    user: row.userName ?? "---",
    territory: row.territoryName ?? "---",
    name: row.name,
    addressOne: row.addressOne,
    addressTwo: row.addressTwo ?? "---",
    defaultAttn: row.attn ?? "---",
    city: row.city,
    state: row.stateOrRegion,
    region: row.region ?? "---",
    zip: row.zip,
    phoneNumber: row.phoneNumber ?? "---",
    countryName: row.countryName ?? "---",
  }));

  return {
    data: mappedReport,
    headers: headers,
  };
};

export const orderHistoryByVariant = (categories, organization) => {
  const categoryHeaders = categories.map((c) => ({
    label: c.name,
    key: c.id,
  }));
  const headers = [
    { label: "Order #", key: "orderId" },
    { label: "Order Type", key: "orderType" },
    { label: "Sku", key: "sku" },
    { label: "Product Identifier", key: "customerIdentifier" },
    { label: "Item name", key: "name" },
    { label: "Ordered By", key: "user" },
    ...categoryHeaders,
    { label: "Variant", key: "variantNames" },
    { label: "Address Name", key: "addressName" },
    { label: "Address 1", key: "streetAddressOne" },
    { label: "Address 2", key: "streetAddressTwo" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Zip", key: "zip" },
    { label: "Store ID", key: "addressExternalId" },
    { label: "Program", key: "program" },
    { label: "Total Qty", key: "count" },
    { label: "Promotion", key: "promotionNames" },
    { label: "Promotion Discount", key: "discount" },
    { label: "Price", key: "price" },
    { label: "Extended Price", key: "totalPrice" },
    { label: "Payment Type", key: "orderCheckoutType" },
    organization.budgetLocation && { label: "Budget", key: "budget" },
    organization.usesCostCenters && { label: "Cost Center", key: "costCenter" },
    { label: "Order Date", key: "submittedAt" },
    { label: "Expedited Date", key: "orderExpeditedDate" },
    { label: "Est. Ship Date", key: "inMarketDate" },
    { label: "Act. Ship Date", key: "shipDate" },
    { label: "Carrier", key: "carrier" },
    { label: "Tracking", key: "tracking" },
    { label: "Status", key: "status" },
  ].filter(Boolean);

  const mapFunction = (report) =>
    report.map((r) => {
      let data = {
        ...r,
        orderType: upCase(r.orderType, "-"),
        customerIdentifier: r.item.customerIdentifier,
        discount: formatMoney(r.discount, false),
        price: formatMoney(r.price, false),
        budget: r.budget,
        costCenter: r.costCenterName,
        carrier: r.trackingData.carrier,
        tracking: r.trackingData.number,
        status:
          r.status === "canceled"
            ? upCase(r.status, "-")
            : upCase(r.orderStatus, "-"),
      };

      categories.forEach(
        (c) =>
          (data[c.id] =
            r.item.groups.find((group) => group.groupCategoryId === c.id)
              ?.name ?? "---")
      );

      return data;
    });

  return {
    mapFunction,
    headers,
  };
};

export const orderHistoryByOrder = (organization) => {
  const headers = [
    { label: "Order #", key: "orderId" },
    { label: "Order Type", key: "type" },
    { label: "Ordered By", key: "user" },
    { label: "Address Name", key: "addressName" },
    { label: "Address 1", key: "addressStreetOne" },
    { label: "Address 2", key: "addressStreetOne" },
    { label: "City", key: "addressCity" },
    { label: "State", key: "addressState" },
    { label: "Zip", key: "addressZipCode" },
    { label: "Store ID", key: "addressExternalId" },
    { label: "Program", key: "program" },
    { label: "Order Date", key: "submittedAt" },
    { label: "Expedited Date", key: "expeditedDate" },
    { label: "Total Qty", key: "count" },
    { label: "Promotion", key: "promotionNames" },
    { label: "Promotion Discount", key: "discount" },
    { label: "Extended Total", key: "extendedTotal" },
    { label: "Order Total", key: "orderTotal" },
    { label: "Payment Type", key: "checkoutType" },
    organization.budgetLocation && { label: "Budget", key: "budget" },
    organization.usesCostCenters && {
      label: "Cost Center",
      key: "costCenter",
    },
    { label: "Status", key: "status" },
  ].filter(Boolean);

  const mapFunction = (report) =>
    report.map((r) => ({
      ...r,
      orderId: r.id,
      addressName: r.address.name,
      addressStreetOne: r.address.streetOne,
      addressStreetTwo: r.address.streetTwo,
      addressCity: r.address.city,
      addressState: r.address.state,
      addressZipCode: r.address.zip,
      discount: formatMoney(r.discount),
      extendedTotal: formatMoney(r.totalPrice),
      orderTotal: handleOrderGrandTotal(r, organization),
      budget: r.budget ?? "---",
      costCenter: r.costCenterName,
      status: upCase(r.status),
    }));

  return {
    mapFunction,
    headers,
  };
};

export const budgetData = (report, budgetLocation) => {
  const headers = [
    { label: "Id", key: "id" },
    { label: "Name", key: "name" },
    { label: "Start Date", key: "startDate" },
    { label: "Expiration Date", key: "expirationDate" },
    { label: "Total Budget", key: "totalAmount" },
    { label: "Total Pending", key: "pendingSpend" },
    { label: "Total Spent", key: "spend" },
    { label: "Balance", key: "balance" },
    { label: "Assigned To", key: "assignedTo" },
    { label: "Status", key: "status" },
  ];

  const mappedReport = report.map((r) => ({
    ...r,
    totalAmount: formatMoney(r.totalAmount, false),
    pendingSpend: formatMoney(r.pendingSpend, false),
    spend: formatMoney(r.spend, false),
    balance: formatMoney(r.balance, false),
    assignedTo: handleBudgetAssignment(r, budgetLocation),
    status: r.isActive ? "Active" : "Inactive",
  }));

  return {
    data: mappedReport,
    headers: headers,
  };
};

export const purchaseOrderRollup = (categories, report) => {
  const categoryHeaders = categories.map((c) => ({
    label: c.name,
    key: c.id,
  }));

  const headers = [
    { label: "Sku", key: "sku" },
    { label: "Supplier", key: "supplier" },
    { label: "Territory", key: "territoryName" },
    { label: "Program", key: "programName" },
    { label: "Order Window", key: "orderWindowName" },
    { label: "Variant", key: "variantNames" },
    ...categoryHeaders,
    { label: "Total Ordered", key: "count" },
    { label: "MOQ", key: "moq" },
    { label: "Cost", key: "cost" },
    { label: "Total Cost", key: "totalCost" },
    { label: "Order Approval Date", key: "approvedAt" },
  ];

  const mappedReport = report.map((r) => {
    let data = {
      sku: r.sku,
      supplier: r.supplier,
      territoryName: r.territoryName,
      programName: r.programName,
      orderWindowName: r.orderWindowName,
      variantNames: r.variantNames,
      count: r.count,
      moq: r.moq > 0 ? r.moq : "---",
      cost: formatMoney(r.cost),
      totalCost: formatMoney(r.totalCost),
      approvedAt: r.approvedAt,
    };

    categories.forEach(
      (c) =>
        (data[c.id] =
          r.item.groups.find((group) => group.groupCategoryId === c.id)?.name ??
          "---")
    );

    return data;
  });

  return {
    data: mappedReport,
    headers: headers,
  };
};
