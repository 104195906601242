/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useFormContext } from "react-hook-form";

import { DeleteRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { ControlledTextInput as TextInput } from "@utils/forms";

import BlockCard from "../../../ui/BlockCard";

const SpecificationsBlock = () => {
  const { watch, control, setValue } = useFormContext();
  const specification = watch("specification");

  const handleDelete = (index) => {
    const newSpecifications = [
      ...specification.slice(0, index),
      ...specification.slice(index + 1),
    ];
    setValue(`specification`, newSpecifications);
  };

  const handleAddNewRow = () => {
    setValue(`specification`, [...specification, { name: "", value: "" }]);
  };

  return (
    <BlockCard title="Specifications" tw="space-y-4">
      {specification.map((_, i) => (
        <div key={i} tw="flex gap-4 items-center">
          <TextInput
            label={i === 0 && "Name"}
            name={`specification.${i}.name`}
            control={control}
          />
          <TextInput
            label={i === 0 && "Value"}
            name={`specification.${i}.value`}
            control={control}
          />
          <IconButton onClick={() => handleDelete(i)} title="Delete">
            <DeleteRounded />
          </IconButton>
        </div>
      ))}

      <StyledButton outlined onClick={handleAddNewRow}>
        New Row
      </StyledButton>
    </BlockCard>
  );
};

export default SpecificationsBlock;
