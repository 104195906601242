import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import _ from "lodash";

import { BudgetWithCalcs } from "@models/Budget";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

import useBudgetAssignedResource from "../useBudgetAssignedResource";

export const budgetsKeyFactory = createQueryKeys("budgets", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client
        .get<BudgetWithCalcs>(`budgets/${id}`, {
          params: { include: "budget_calculations" },
        })
        .then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<BudgetWithCalcs[]>("budgets", {
        params: { ...params, include: "budget_calculations" },
      }),
  }),
});

export const useBudgetQuery = (id: string | null | undefined) =>
  useQuery({
    ...budgetsKeyFactory.detail(id!),
    enabled: !!id,
    staleTime: 60_1000,
  });

export const usePaginatedBudgetsQuery = buildPaginatedQuery(
  budgetsKeyFactory.paginated,
  {
    staleTime: 60_1000,
    placeholderData: keepPreviousData,
  }
);

type CreateBudgetPayload = {
  name: string;
  isActive?: boolean;
  startDate: string | null;
  expirationDate: string | null;
  totalAmount: string;
  users?: string[];
  territories?: string[];
  groups?: string[];
};

export const useCreateBudgetMutation = () => {
  const queryClient = useQueryClient();
  const assignedResource = useBudgetAssignedResource();

  return useMutation({
    mutationFn: (data: CreateBudgetPayload) =>
      client
        .post<BudgetWithCalcs>(
          "budgets",
          {
            __type: "budget",
            note: "Budget created",
            ..._.omit(data, ["users", "territories", "groups"]),
            [assignedResource]: _.map(data[assignedResource], (id) => ({
              id,
            })),
            relationshipNames: [assignedResource],
          },
          {
            params: { include: "budget_calculations" },
          }
        )
        .then((res) => res.data),
    onSuccess: (budget) => {
      queryClient.invalidateQueries({
        queryKey: budgetsKeyFactory.paginated._def,
      });
      return queryClient.setQueryData(
        budgetsKeyFactory.detail(budget.id).queryKey,
        budget
      );
    },
  });
};
type UpdateBudgetPayload = Partial<CreateBudgetPayload> & {
  id: string;
  note: string;
};

export const useUpdateBudgetMutation = () => {
  const queryClient = useQueryClient();
  const assignedResource = useBudgetAssignedResource();

  return useMutation({
    mutationFn: ({ id, ...data }: UpdateBudgetPayload) =>
      client
        .update<BudgetWithCalcs>(
          `budgets/${id}`,
          {
            __type: "budget",
            ..._.omit(data, ["users", "territories", "groups"]),
            [assignedResource]: _.map(data[assignedResource], (id) => ({
              id,
            })),
            relationshipNames: [assignedResource],
          },
          {
            params: { include: "budget_calculations" },
          }
        )
        .then((res) => res.data),
    onSuccess: (budget) => {
      queryClient.invalidateQueries({
        queryKey: budgetsKeyFactory.paginated._def,
      });
      return queryClient.setQueryData(
        budgetsKeyFactory.detail(budget.id).queryKey,
        budget
      );
    },
  });
};
