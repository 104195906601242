import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const writeWorkbookToFile = async (workbook) => {
  const buffer = await workbook.xlsx.writeBuffer();
  return new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
};

const downloadFile = (blob: Blob, filename: string) => {
  saveAs(blob, `${filename}.xlsx`);
};

const createWorkbook = async (data: any[]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  // Define headers
  const headers = [
    "SKU",
    "Item Name",
    "Packout",
    "Category",
    "Current On Hand",
    "Current Allocated Inventory",
    "Current Unallocated Inventory",
  ];

  // Track column indices for coloring
  const territoryColumnRanges: { start: number; end: number }[] = [];
  let currentColumn = headers.length + 1; // Start after the initial headers

  // Add territory headers and track their column ranges
  data[0]?.territories.forEach(territory => {
    const startColumn = currentColumn;
    
    territory.territory_users.forEach(user => {
      headers.push(user.user_name);
      currentColumn++;
    });
    headers.push(`${territory.territory_name} Total`);
    
    territoryColumnRanges.push({
      start: startColumn,
      end: currentColumn,
    });
    currentColumn++;
  });

  // Add headers to the worksheet
  const headerRow = worksheet.addRow(headers);
  
  // Set specific column widths (width in Excel is in characters, roughly 7 pixels per character)
  worksheet.getColumn('A').width = 17.85;  // 125px / 7
  worksheet.getColumn('B').width = 60.71;  // 425px / 7
  worksheet.getColumn('C').width = 6.28;   // 44px / 7
  worksheet.getColumn('D').width = 22.14;  // 155px / 7
  worksheet.getColumn('E').width = 12.14;  // 85px / 7
  worksheet.getColumn('F').width = 19.57;  // 137px / 7
  worksheet.getColumn('G').width = 21.28;  // 149px / 7

  // Make only territory name cells bold
  territoryColumnRanges.forEach(range => {
    const territoryNameCell = headerRow.getCell(range.end);
    territoryNameCell.font = { bold: true };
  });
  
  // Freeze header row and first two columns
  worksheet.views = [{ 
    state: 'frozen', 
    xSplit: 2,  // Freeze columns A and B
    ySplit: 1   // Freeze top row
  }];
  worksheet.properties.defaultRowHeight = 20;

  // Predefined contrasting colors (can add more if needed)
  const colors = [
    'FFB7E1CD', // light green
    'FFFFD966', // yellow
    'FFF4B084', // orange
    'FF9BC2E6', // light blue
    'FFFF9999', // light red
    'FFB4A7D6', // purple
    'FF93C47D', // darker green
    'FFE69138', // darker orange
    'FF6FA8DC', // darker blue
    'FFE06666', // darker red
  ];

  // Apply colors to territory columns and make territory name columns bold
  territoryColumnRanges.forEach((range, index) => {
    const color = colors[index % colors.length];
    
    for (let col = range.start; col <= range.end; col++) {
      const column = worksheet.getColumn(col);
      column.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color },
      };
      
      // Make the entire territory name column bold (last column in each range)
      if (col === range.end) {
        column.font = { bold: true };
      }
    }
  });

  // Populate rows with data
  data.forEach(item => {
    const rowData = [
      item.variantSku || "BrandHub SKU",
      item.itemName,
      item.packSize,
      item.groupName,
      item.currOnHandInventory || null,
      item.currAllocatedInventory === 0 ? null : item.currAllocatedInventory,
      item.currUnallocatedInventory === 0 ? null : item.currUnallocatedInventory,
    ];

    // Add territory data with user allocations and territory total in correct position
    item.territories.forEach(territory => {
      // Add user allocations
      territory.territory_users.forEach(user => {
        rowData.push(user.user_allocation === 0 ? null : user.user_allocation);
      });
      // Add territory total in the same column as territory name
      rowData.push(territory.territory_total_allocation === 0 ? null : territory.territory_total_allocation);
    });

    worksheet.addRow(rowData);
  });

  // Add borders to all cells in colored columns after data is populated
  territoryColumnRanges.forEach((range) => {
    for (let col = range.start; col <= range.end; col++) {
      worksheet.eachRow((row) => {
        const cell = row.getCell(col);
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    }
  });

  return workbook;
}

export default async function downloadAllocationSnapshotReportExcel(data) {
  const workbook = await createWorkbook(data);
  const blob = await writeWorkbookToFile(workbook);
  downloadFile(blob, 'AllocationDetailReport');
}