/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useApiResource } from "@services/api";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledFilterAutocomplete from "./ControlledFilterAutocomplete";

const useTerritories = () => {
  const res = useApiResource("territories", {
    sort: "name",
    revalidate: false,
  });
  return {
    ...res,
    territories: res.data ?? [],
  };
};
const TerritoryPopper = () => {
  const { control } = useFilterContext();
  const { territories, isLoading } = useTerritories();

  return (
    <ControlledFilterAutocomplete
      name="territories"
      options={territories}
      control={control}
      inputPlaceholder="Filter Territories"
      isLoading={isLoading}
    />
  );
};

const TerritoryFilterChip = (props) => {
  const { territories } = useTerritories();
  const { filterValues } = useFilterContext();
  const selectedTerritories = filterValues["territories"] ?? [];

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">Territory is&nbsp;</span>
      <span tw="text-neutral-800 font-medium">
        {[...selectedTerritories]
          .splice(0, 2)
          .map((id) => territories.find((terr) => terr.id === id)?.name)
          .filter(Boolean)
          .join(", ")}
        {selectedTerritories.length > 2 && (
          <span>, + {selectedTerritories.length - 2}</span>
        )}
      </span>
    </Chip>
  );
};

const TerritoryFilter = {
  Chip: TerritoryFilterChip,
  Popper: TerritoryPopper,
};

export default TerritoryFilter;
