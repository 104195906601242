/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

import { Skeleton } from "@mui/material";

import { xor } from "lodash";

import {
  ItemCatalogView,
  ItemViewButtons,
  usePaginatedItemsQuery,
} from "@features/items";

import {
  FixedHeightScrollLastChild,
  StyledButton,
} from "../../../components/StyledComponents";
import { Item } from "../../../models";
import { useApiResource } from "../../../services/api";
import { wait } from "../../../utility/utilityFunctions";
import { useFilterParams } from "../../filters";
import Filters from "../../filters/components/Filters";

const getLabelText = (item: Item, assignedItemIds: string[]) =>
  assignedItemIds.includes(item.id) ? "Remove" : "Add";

const AssignToProgramButton = ({
  item,
  assignedItemIds,
  toggleAssignment,
}: {
  item: Item;
  assignedItemIds: string[];
  toggleAssignment: (id: string) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(getLabelText(item, assignedItemIds));

  const handleAddToCart = async () => {
    setLoading(true);
    // insures loading animation is shown for at least .8 seconds
    await Promise.all([toggleAssignment(item.id), wait(800)]);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) return;
    setMessage(getLabelText(item, assignedItemIds));
  }, [loading, item, assignedItemIds]);

  return (
    <StyledButton
      outlined
      loading={loading}
      onClick={handleAddToCart}
      tw="whitespace-nowrap"
    >
      {message}
    </StyledButton>
  );
};

const ChannelTitle = ({ channelId }) => {
  const { data } = useApiResource("channels", { id: channelId as string });
  if (!data)
    return (
      <Skeleton tw="inline">
        <b>Channel</b>
      </Skeleton>
    );
  return <b>{data.name}</b>;
};

const ItemAssignment = ({
  saveItemsOnly,
  savedItemIds,
}: {
  saveItemsOnly: (itemIds: string[]) => Promise<any>;
  savedItemIds: string[];
}) => {
  const [filterParams] = useFilterParams();

  const programId = useWatch({ name: "id" });
  const channelId = useWatch({ name: "channelId" });

  const defaultFilters = {
    ...(savedItemIds.length > 0 && { programs: [programId] }),
  };

  const { data: items, ...tableProps } = usePaginatedItemsQuery({
    filter: {
      channelIds: channelId ? [channelId] : undefined,
      query: filterParams.q,
      favorites: filterParams.favorites,
      programIds: filterParams.programs,
      groupIds:
        filterParams.groups && Object.values(filterParams.groups).flat(),
    },
    sort: filterParams.sort,
  });

  const toggleAssignment = async (itemId: string) => {
    const newAssignedItemIds = xor(savedItemIds, [itemId]);
    saveItemsOnly(newAssignedItemIds);
  };

  return (
    <FixedHeightScrollLastChild tw="[--space-after-header: 54px]">
      {channelId && (
        <h3>
          Showing item belonging to this program's channel:{" "}
          <ChannelTitle channelId={channelId} />
        </h3>
      )}
      <div tw="flex gap-2 items-start justify-between">
        <Filters
          searchTitle="Search Items"
          slots={["favorites", "groups", "programs"]}
          defaultValues={defaultFilters}
        />
        <ItemViewButtons />
      </div>
      <ItemCatalogView
        rows={items}
        query={filterParams.q}
        rowActions={(item) => (
          <AssignToProgramButton
            item={item}
            assignedItemIds={savedItemIds}
            toggleAssignment={toggleAssignment}
          />
        )}
        {...tableProps}
      />
    </FixedHeightScrollLastChild>
  );
};

export default ItemAssignment;
