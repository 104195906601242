import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/*
This hook handles generating of report CSV files. It accepts the report information, loading states,
and returns the formatted csv data when the report is present.
*/

export const useReportCsv = (
  type,
  hasGenerated,
  setHasGenerated,
  isLoading,
  report,
  headers
) => {
  const [csvData, setCsvData] = useState({ data: [], headers: [] });
  const reportType = useSelector((state) => state.reports.reportType);

  useEffect(() => {
    setCsvData({ data: [], headers: [] });
  }, [type]);

  useEffect(() => {
    if (
      ((hasGenerated && !isLoading && report.length > 0) ||
        (report.length > 0 && csvData.data.length === 0)) &&
      reportType === type
    ) {
      let data = [];
      report.forEach((repItem) => {
        let dataObject = {
          ...repItem,
        };
        data.push(dataObject);
      });
      setCsvData({ data: data, headers: headers });
      setHasGenerated(false);
    }
  }, [
    csvData,
    reportType,
    hasGenerated,
    isLoading,
    report,
    setHasGenerated,
    type,
    headers,
  ]);

  return {
    data: csvData.data,
  };
};
