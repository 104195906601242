/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonGroup, Switch, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SelectInput } from "@components/../utils/forms";
import AddToTerritoriesModal from "@components/Settings/General/AddToTerritoriesModal";
import {
  Contained,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import SingleCountryAutoComplete from "@components/Utility/AutoCompleteFields/SingleCountryAutoComplete";
import ImageWrapper from "@components/Utility/ImageWrapper";
import Loading from "@components/Utility/Loading";
import OptionTable from "@components/Utility/OptionTable";
import { updateOrganization } from "@redux/slices/user/currentUserSlice";
import { useApiResource } from "@services/api";
import { useOpenCloudinaryWidget } from "@services/cloudinary";
import DocTitle from "@utility/DocTitle";

import { useNumberOnlyInput } from "../hooks/inputs/useNumberOnlyInput";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const SectionLabel = tw("h4")`font-medium text-neutral-500 mt-6`;

const StyledSection = styled("section")({
  "&": tw`flex flex-col mb-8 border-b-2 border-neutral-200 lg:(flex-row mb-0)`,
  "& > *": tw`flex-1 p-4 lg:py-12 max-w-prose`,
  "& > *:first-of-type": tw`flex-[0.85] 2xl:flex-[0.5]`,
  "& > *:last-of-type": tw`lg:pl-8`,
  p: tw`mt-1 text-neutral-600`,
});

const Section = ({ title, desc, children, ...props }) => (
  <StyledSection {...props}>
    <div>
      <h3 tw="text-xl font-bold text-neutral-700">{title}</h3>
      <p>{desc}</p>
    </div>
    <div>{children}</div>
  </StyledSection>
);

const OnOffSwitch = ({ className, ...props }) => (
  <div className={className}>
    <Switch {...props} />
    <span css={!props.checked && tw`text-neutral-500`}>
      {props.checked || props.disabled ? "Enabled" : "Disabled"}
    </span>
  </div>
);

const AdminGeneral = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const openCloudinaryWidget = useOpenCloudinaryWidget();

  const {
    isUpdateLoading,
    organization: {
      id,
      name,
      logoUrl,
      usesApprovalFlow,
      includesTaxes,
      includeTaxesInInventoryOrders,
      budgetLocation,
      budgetCategoryId,
      includeShippingInBudgets,
      includeTaxesInBudgets,
      estimatedShippingPercentage,
      minimumShippingCost: minimumShippingCostSaved,
      approvalThreshold,
      usesOnDemand,
      usesInventory,
      preferExternalId,
      preferProductGridView,
      usesItemCustomization,
      addressBookType,
      usesStripe,
      usesStandardCheckout,
      includeShippingInStripeCharge,
      includeTaxesInStripeCharge,
      allocationLocation,
      orderLimitType,
      countries,
      usesCostCenters,
    },
  } = useSelector((state) => state.currentUser);

  const {
    value: threshold,
    bind: bindThreshold,
    setValue: setThreshold,
    change,
    setChange,
  } = useNumberOnlyInput((approvalThreshold / 100).toFixed(2));
  const {
    value: shippingPercentage,
    bind: bindShippingPercentage,
    change: shippingPercentageChange,
    setChange: setShippingPercentageChange,
  } = useNumberOnlyInput((estimatedShippingPercentage * 100).toString());

  const {
    value: minimumShippingCost,
    bind: bindMinimumShippingCost,
    change: minimumShippingCostChange,
    setChange: setMinimumShippingCostChange,
  } = useNumberOnlyInput(minimumShippingCostSaved.toFixed(2));

  const { categories } = useSelector((state) => state.groupCategories);
  const categoryOptions = categories.map(({ id, name }) => ({
    value: id,
    name,
  }));

  const [updateCountries, setUpdateCountries] = useState([...countries]);
  const [reset, setReset] = useState(false);
  const [addToTerritory, setAddToTerritory] = useState(null);

  const { data: budgetData } = useApiResource("budgets", {
    params: { page: { size: 1 }, include: "" },
  });
  const hasBudgets = budgetData?.length > 0 ?? false;

  const handleAddCountry = (country) => {
    if (!updateCountries.find((c) => c.id === country.id)) {
      const update = updateCountries.concat(country);
      dispatch(
        updateOrganization({}, update, id, () => {
          setUpdateCountries(update);
          setAddToTerritory(country);
        })
      );
    }
    setReset(true);
  };

  const handleRemoveCountry = (countryId) => {
    if (updateCountries.length > 1) {
      const update = updateCountries.filter((c) => c.id !== countryId);
      dispatch(
        updateOrganization({}, update, id, () => {
          setUpdateCountries(update);
        })
      );
    }
  };

  const handleUpload = () => {
    openCloudinaryWidget({
      config: { folder: "prod/Brandhub/org-logos" },
      onSuccess: (file) =>
        dispatch(
          updateOrganization(
            { "logo-cloudinary-id": file.info.public_id },
            countries,
            id
          )
        ),
    });
  };

  const handleApprovalFlow = () => {
    if (usesApprovalFlow) {
      dispatch(
        updateOrganization(
          {
            "uses-approval-flow": false,
            "uses-approval-threshold": false,
            "approval-threshold": "0.00",
          },
          countries,
          id
        )
      );
      setThreshold("0.00");
    } else {
      dispatch(
        updateOrganization({ "uses-approval-flow": true }, countries, id)
      );
    }
  };

  const updateThreshold = () => {
    if (+threshold === 0) {
      dispatch(
        updateOrganization(
          { "approval-threshold": "0", "uses-approval-threshold": false },
          countries,
          id
        )
      );
    } else {
      dispatch(
        updateOrganization(
          { "approval-threshold": threshold, "uses-approval-threshold": true },
          countries,
          id
        )
      );
    }
    setChange(false);
  };

  const handleOrderFlow = (type) => {
    if (type === "inventory") {
      dispatch(
        updateOrganization({ "uses-inventory": !usesInventory }, countries, id)
      );
    } else {
      dispatch(
        updateOrganization({ "uses-on-demand": !usesOnDemand }, countries, id)
      );
    }
  };

  const handleItemCustomization = () => {
    dispatch(
      updateOrganization(
        { "uses-item-customization": !usesItemCustomization },
        countries,
        id
      )
    );
  };

  const handleIncludesTaxes = () => {
    const newIncludesTaxes = !includesTaxes;
    dispatch(
      updateOrganization(
        {
          "includes-taxes": newIncludesTaxes,
          ...(!newIncludesTaxes && {
            "include-taxes-in-budgets": false,
            "include-taxes-in-stripe-charge": false,
            "include-taxes-in-inventory-orders": false,
          }),
        },
        countries,
        id
      )
    );
  };
  const updateCostCenters = () => {
    dispatch(
      updateOrganization(
        {
          "uses-cost-centers": !usesCostCenters,
        },
        countries,
        id
      )
    );
  };
  const updateBudget = (location, categoryId) => {
    dispatch(
      updateOrganization(
        {
          "budget-location": location,
          "budget-group-category-id": categoryId ? +categoryId : null,
        },
        countries,
        id
      )
    );
  };

  const handleBudgetOptions = (optionType) => {
    if (optionType === "shipping") {
      dispatch(
        updateOrganization(
          { "include-shipping-in-budgets": !includeShippingInBudgets },
          countries,
          id
        )
      );
    } else {
      dispatch(
        updateOrganization(
          { "include-taxes-in-budgets": !includeTaxesInBudgets },
          countries,
          id
        )
      );
    }
  };

  const handlePreferWarehouseId = () => {
    dispatch(
      updateOrganization(
        { "prefer-warehouse-id": !preferExternalId },
        countries,
        id
      )
    );
  };
  const handlePreferProductGridView = () => {
    dispatch(
      updateOrganization(
        { "prefer-product-grid-view": !preferProductGridView },
        countries,
        id
      )
    );
  };

  const updateInventoryAllocation = (location) => {
    dispatch(
      updateOrganization({ "allocation-location": location }, countries, id)
    );
  };

  const updateAllocationOrderLimitType = (type) => {
    dispatch(updateOrganization({ "order-limit-type": type }, countries, id));
  };

  const updateShippingPercentage = () => {
    dispatch(
      updateOrganization(
        {
          "estimated-shipping-percentage": parseFloat(shippingPercentage) / 100,
        },
        countries,
        id
      )
    );
    setShippingPercentageChange(false);
  };

  const updateMinimumShippingCost = () => {
    dispatch(
      updateOrganization(
        { "minimum-shipping-cost": parseFloat(minimumShippingCost) },
        countries,
        id
      )
    );
    setMinimumShippingCostChange(false);
  };

  const handleStripe = () => {
    dispatch(updateOrganization({ "uses-stripe": !usesStripe }, countries, id));
  };

  const handleStandardCheckout = () => {
    dispatch(
      updateOrganization(
        { uses_standard_checkout: !usesStandardCheckout },
        countries,
        id
      )
    );
  };

  const handleStripeOptions = (type) => {
    if (type === "shipping") {
      dispatch(
        updateOrganization(
          {
            "include-shipping-in-stripe-charge": !includeShippingInStripeCharge,
          },
          countries,
          id
        )
      );
    } else {
      dispatch(
        updateOrganization(
          {
            "include-taxes-in-stripe-charge": !includeTaxesInStripeCharge,
          },
          countries,
          id
        )
      );
    }
  };

  return (
    <>
      <DocTitle title={"General Admin"} />
      <Contained>
        {isUpdateLoading && <Loading opacity="50%" />}
        <h2 tw="font-bold text-2xl mb-12">Brandhub Configuration for {name}</h2>
        <Section
          title="Organization Logo"
          desc="users' dashboard on the home page."
        >
          <div tw="space-y-4">
            <OpaqueCard tw="py-6">
              <ImageWrapper
                tw="w-full max-w-md"
                alt={`${name} Logo`}
                imgUrl={logoUrl}
              />
            </OpaqueCard>
            <StyledButton cta onClick={handleUpload}>
              Upload new logo
            </StyledButton>
          </div>
        </Section>
        <Section
          title="Approval Flow"
          desc='
              If the approval flow is on, users with the role "Orderer" will need
              their orders approved by users with the role "Approver" or "Admin".
              If it is off, all orders are automatically approved upon submission.
            '
        >
          <div tw="space-y-4">
            <OnOffSwitch
              checked={usesApprovalFlow}
              onChange={handleApprovalFlow}
            />
            <div tw="flex flex-wrap gap-2">
              <TextField
                size="small"
                label="Approval Threshold"
                disabled={!usesApprovalFlow}
                {...bindThreshold}
              />
              <StyledButton
                outlined
                onClick={updateThreshold}
                disabled={!usesApprovalFlow || !change}
              >
                UPDATE THRESHOLD
              </StyledButton>
            </div>
            <p tw="text-sm">
              Users with the role "Orderer" will only need their orders approved
              if their order has a total price higher than the threshold.
            </p>
          </div>
        </Section>
        <Section
          title="Estimated Shipping Percentage"
          desc="
              The estimated shipping percentage is used when determining estimated
              shipping costs on orders. Brandhub estimates shipping by taking this
              percentage of the total order cost.
            "
        >
          <div>
            <div tw="flex flex-wrap gap-2">
              <TextField
                size="small"
                label="Percentage"
                {...bindShippingPercentage}
              />
              <StyledButton
                outlined
                onClick={() => updateShippingPercentage()}
                disabled={!shippingPercentageChange}
              >
                UPDATE PERCENTAGE
              </StyledButton>
            </div>
          </div>
        </Section>
        <Section
          title="Minimum Shipping Cost"
          desc="
              Minimum cost of shipping. If the estimated shipping cost is less than
              this amount, the minimum shipping cost will be used instead.
            "
        >
          <div>
            <div tw="flex flex-wrap gap-2">
              <TextField
                size="small"
                label="Minimum Shipping Cost"
                {...bindMinimumShippingCost}
              />
              <StyledButton
                outlined
                onClick={updateMinimumShippingCost}
                disabled={!minimumShippingCostChange}
              >
                UPDATE MINIMUM COST
              </StyledButton>
            </div>
          </div>
        </Section>
        <Section
          title="Include Taxes"
          desc="
              Calculate and display taxes on Orders and Purchase Orders accross the organization.
            "
        >
          <div>
            <OnOffSwitch
              checked={Boolean(includesTaxes)}
              onChange={handleIncludesTaxes}
            />
          </div>
        </Section>
        {includesTaxes && (
          <Section
            title="Include Taxes in Inventory Orders"
            desc="Calculate and display taxes on Inventory orders."
          >
            <div>
              <OnOffSwitch
                checked={Boolean(includeTaxesInInventoryOrders)}
                onChange={() =>
                  dispatch(
                    updateOrganization(
                      {
                        "include-taxes-in-inventory-orders":
                          !includeTaxesInInventoryOrders,
                      },
                      countries,
                      id
                    )
                  )
                }
              />
            </div>
          </Section>
        )}
        <Section
          title="Cost Centers"
          desc="
              All orders will need to be assigned cost-centers. This allows for
              certain order reports grouped by cost-center
            "
        >
          <div>
            <OnOffSwitch
              checked={Boolean(usesCostCenters)}
              onChange={updateCostCenters}
              disabled={hasBudgets}
            />
            {hasBudgets && (
              <p tw="text-sm">
                Cost centers can't be turned on because budgets currently exist
                on this organization.
              </p>
            )}
          </div>
        </Section>
        <Section
          title="Budgets"
          desc="
              All orders will need to be assigned cost-centers. This allows for
              certain order reports grouped by cost-center
            "
        >
          <div>
            <OnOffSwitch
              checked={Boolean(budgetLocation)}
              onChange={() =>
                Boolean(budgetLocation)
                  ? updateBudget(null, null)
                  : updateBudget("user", null)
              }
              disabled={hasBudgets || usesCostCenters}
            />
            {hasBudgets && (
              <p tw="text-sm">
                Budgets can't be turned off because budgets currently exist on
                this organization.
              </p>
            )}
            {usesCostCenters && (
              <p tw="text-sm">
                Budgets can't be turned on because cost-centers are enabled on
                this organization.
              </p>
            )}
          </div>
        </Section>
        {Boolean(budgetLocation) && (
          <Section
            title="Budget Assignment"
            desc="The level at which budgets get assigned. If you need to update your budget settings, please contact support at help@selectbrandhub.com."
          >
            <ButtonGroup>
              <StyledButton
                disabled={hasBudgets}
                cta={budgetLocation === "user"}
                outlined={budgetLocation !== "user"}
                onClick={() => updateBudget("user", null)}
              >
                USER
              </StyledButton>
              <StyledButton
                disabled={hasBudgets}
                cta={budgetLocation === "territory"}
                outlined={budgetLocation !== "territory"}
                onClick={() => updateBudget("territory", null)}
              >
                TERRITORY
              </StyledButton>
              <StyledButton
                disabled={hasBudgets}
                cta={budgetLocation === "group"}
                outlined={budgetLocation !== "group"}
                onClick={() => updateBudget("group", categories[0].id)}
              >
                CATEGORY
              </StyledButton>
            </ButtonGroup>
            {budgetLocation === "group" && categories.length > 0 && (
              <>
                <SectionLabel tw="mb-2">Category Assignment</SectionLabel>
                <SelectInput
                  tw="min-w-[12em]"
                  label="Category"
                  value={budgetCategoryId}
                  onChange={(evt) => updateBudget("group", evt.target.value)}
                  options={categoryOptions}
                />
              </>
            )}

            <SectionLabel>Include Estimated Shipping in Budgets</SectionLabel>
            <OnOffSwitch
              checked={includeShippingInBudgets}
              onChange={() => handleBudgetOptions("shipping")}
              disabled={hasBudgets}
            />

            <SectionLabel>Include Tax in Budgets</SectionLabel>
            <OnOffSwitch
              checked={includeTaxesInBudgets}
              onChange={() => handleBudgetOptions("taxes")}
              disabled={hasBudgets}
            />
          </Section>
        )}
        <Section
          title="Standard Checkout"
          desc="Enabling standard checkout allows users to check out without selecting a
            budget or paying immediately with a credit card."
        >
          <SectionLabel>Enable Standard Checkout</SectionLabel>
          <OnOffSwitch
            checked={usesStandardCheckout}
            onChange={handleStandardCheckout}
          />
        </Section>
        <Section
          title="Credit Card Payment"
          desc="Enabling credit card payments allows users to check out with credit
              card payments. If credit card payments are turned on, individual
              user permissions can be set through the user admin workflow. All
              users cannot use credit cards by default, so you will need to go and
              set up the users that can use this payment method. If your
              organization uses the approval flow, users checking out with a
              credit card will skip that flow, and their orders will be
              automatically approved."
        >
          <SectionLabel>Enable Credit Card Payment</SectionLabel>
          <OnOffSwitch checked={usesStripe} onChange={handleStripe} />
          {usesStripe && (
            <>
              <SectionLabel>
                Include Estimated Shipping in Credit Card Payments
              </SectionLabel>
              <OnOffSwitch
                checked={includeShippingInStripeCharge}
                onChange={() => handleStripeOptions("shipping")}
              />

              <SectionLabel>Include Tax in Credit Card Payments</SectionLabel>
              <OnOffSwitch
                checked={includeTaxesInStripeCharge}
                onChange={() => handleStripeOptions("taxes")}
              />
            </>
          )}
        </Section>
        <Section
          title="Inventory Allocations"
          desc="Turning on Inventory Allocations will allow you to assign inventory
              to either certain users or territories. If turned on, allocations
              can be edited by navigating to Item Admin in Brand HQ."
        >
          <OnOffSwitch
            checked={Boolean(allocationLocation)}
            onChange={() =>
              Boolean(allocationLocation)
                ? updateInventoryAllocation(null)
                : updateInventoryAllocation("user")
            }
          />
          <SectionLabel>Allocation Assignment</SectionLabel>
          <ButtonGroup>
            <StyledButton
              cta={allocationLocation === "user"}
              outlined={allocationLocation !== "user"}
              onClick={() => updateInventoryAllocation("user")}
            >
              USER
            </StyledButton>
            <StyledButton
              cta={allocationLocation === "territory"}
              outlined={allocationLocation !== "territory"}
              onClick={() => updateInventoryAllocation("territory")}
            >
              TERRITORY
            </StyledButton>
          </ButtonGroup>

          <SectionLabel>Allocation Order Limitation Type</SectionLabel>
          <SelectInput
            value={orderLimitType}
            onChange={(e) => updateAllocationOrderLimitType(e.target.value)}
            options={[
              { id: "allocation-only", name: "Allocation Only" },
              {
                id: "allocation-plus-global",
                name: "Allocation Plus Additional",
              },
              {
                id: "allocation-only-or-global",
                name: "Allocation Only or Additional",
              },
            ]}
          />
          <p tw="text-sm pt-4">
            If the allocation order limitation is Allocation Only, users will
            only be able to place orders for inventoried items if they have
            allocations that are either directly assigned to them or assigned to
            their territory. If the allocation order limitation is Allocation
            Plus Additional, users can still place orders for items not
            allocated to them if there is any unallocated inventory at the
            warehouse, and users with allocations can order more than the
            allocated amount if there is more available at the warehouse. If the
            allocation order limitation is Allocation Only or Additional, users
            that have allocations can only order what is allocated to them, and
            users without allocations can only order from unallocated inventory
            that is available at the warehouse.
          </p>
        </Section>
        <Section
          title="Address Book Assignment"
          desc="Address Book Assignment determines how users input, and view
              addresses in their address books. If it is assigned to Territory,
              users can see all addresses that have been submitted for their
              assigned territory (or territories), and any address they create is
              visible to everyone else in the territory in which it was created.
              If it is assigned to User, users will only ever be able to see
              addresses that they themselves have created."
        >
          <SelectInput
            tw="w-[12em]"
            value={addressBookType}
            onChange={(e) =>
              dispatch(
                updateOrganization(
                  { "address-book-type": e.target.value },
                  countries,
                  id
                )
              )
            }
            options={[
              { id: "territory", name: "Territory" },
              { id: "user", name: "User" },
            ]}
          />
        </Section>
        <Section
          title="On Demand Orders"
          desc="Enabling on demand orders will allow ordering for items that still need to be produced. On demand orders are processed through the purchase order work flow. On Demand ordering cannot be turned off if there are active on demand orders."
        >
          <OnOffSwitch
            checked={usesOnDemand}
            onChange={() => handleOrderFlow("on-demand")}
          />
        </Section>
        <Section
          title="Inventory Orders"
          desc="Enabling on Inventory orders will allow for ordering against inventory at organization assigned warehouses. Inventory Ordering can only be enabled if you have set up warehouse integrations with Brandhub support. For any questions, please contact help@selectbrandhub.com. Inventory ordering cannot be turned off if there are active inventory orders."
        >
          <OnOffSwitch
            checked={usesInventory}
            onChange={() => handleOrderFlow("inventory")}
          />
        </Section>
        <Section
          title="Item Customization"
          desc="Enabling item customization allows users to add customizations to
              items such as custom text on apparel. If turned on, an item
              customization can be added to items when creating or updating them,
              and users will be prompted while ordering to enter their
              customization. Inventory items cannot be customized."
        >
          <OnOffSwitch
            checked={usesItemCustomization}
            onChange={() => handleItemCustomization()}
          />
        </Section>
        <Section
          title="Prefer warehouse ID"
          desc={`Enabling "prefer warehouse ID" will affect how tables display the
              item identifiers. If enabled, the ID columns will display the item's
              given warehouse ID instead of the Brandhub-generated sequence
              number.`}
        >
          <OnOffSwitch
            checked={preferExternalId}
            onChange={() => handlePreferWarehouseId()}
          />
        </Section>
        <Section
          title="Prefer Item Grid View"
          desc={`Enabling Prefer Item Grid View will default the item view to Grid
              instead of List.`}
        >
          <OnOffSwitch
            checked={preferProductGridView}
            onChange={() => handlePreferProductGridView()}
          />
        </Section>
        <Section
          title="Shipping Countries"
          desc={`Select what countries your organization will ship goods to. You must
              select at least one country.`}
        >
          <div tw="w-full">
            {addToTerritory && (
              <AddToTerritoriesModal
                country={addToTerritory}
                handleClose={() => setAddToTerritory(null)}
              />
            )}
            <SingleCountryAutoComplete
              classes={classes}
              handleChange={handleAddCountry}
              reset={reset}
              setReset={setReset}
              disabled={false}
            />
            <OptionTable
              headCells={[
                { id: "id", label: "Id" },
                { id: "name", label: "Country" },
              ]}
              options={updateCountries}
              handleRemove={handleRemoveCountry}
              isRemoveable
            />
          </div>
        </Section>
        <Section />
      </Contained>
    </>
  );
};

export default AdminGeneral;
