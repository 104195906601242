/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";
import { useFormContext } from "react-hook-form";

import _ from "lodash";

import { MinimalItemCard, variantName } from "@features/items";
import { Item } from "@models/Item";
import { ControlledTextInput, intValidation } from "@utils/forms";

const ColHeader = tw.h2`font-medium tracking-wide text-neutral-500 text-sm`;

const ItemVariantInventoryQtyForm = ({
  item,
  disabledSkus,
}: {
  item: Item;
  disabledSkus?: string[];
}) => {
  const { control } = useFormContext();
  const variants = _(item.variants)
    .filter("isActive")
    .sortBy("orderPosition")
    .value();
  return (
    <form>
      <MinimalItemCard
        tw="flex gap-4 mb-6 items-start"
        item={item}
        disabledInteractive
        imageProps={{ style: { width: "10em", flexShrink: 0 } }}
        includeDescription
      />
      <div tw="grid grid-cols-3 items-end gap-2">
        <ColHeader>Variant</ColHeader>
        <ColHeader tw="justify-self-center">Current Inventory</ColHeader>
        <ColHeader>Quantity</ColHeader>
        {variants.map((v) => (
          <React.Fragment key={v.id}>
            <div>
              {variantName(v) || "Default"}
              {<div tw="text-neutral-500 text-xs">{v.variantSku}</div>}
            </div>
            <div tw="justify-self-center text-primary-800">
              {v.cachedWarehouseQty}
            </div>
            {disabledSkus?.includes(v.variantSku) ? (
              <div tw="text-neutral-600">Variant Already Added</div>
            ) : (
              <ControlledTextInput
                control={control}
                inputMode="numeric"
                name={v.id}
                rules={intValidation}
                placeholder="0"
                tw="-mb-1.5"
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </form>
  );
};

export default ItemVariantInventoryQtyForm;
