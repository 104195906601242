/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Control, Controller } from "react-hook-form";

import { Autocomplete, Chip, CircularProgress } from "@mui/material";

import { pick } from "lodash";

import { useApiResource } from "@services/api";
import { StyledTextInput } from "@utils/forms/ControlledInputs";

const RfqQuantitiesInput = ({
  control,
  name,
  onChange,
  ...props
}: {
  control: Control<any>;
  name: string;
  placeholder?: string;
  helperText?: string;
  label?: string;
  onChange?: (value: any) => void;
}) => {
  const { data: suppliers, isLoading } = useApiResource("suppliers");

  const options = (suppliers ?? []).map((s) => pick(s, ["id", "name"]));

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required:
          "You must select at least 1 supplier to send the quote request.",
      }}
      render={({ field, fieldState }) => (
        <Autocomplete
          multiple
          options={options}
          loading={isLoading}
          css={{ input: field.value?.length > 0 && tw`ml-2` }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index: number) => (
              <Chip
                variant="outlined"
                tw="flex last:mr-6! bg-neutral-100 border-neutral-200"
                label={option?.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <StyledTextInput
              {...params}
              {...props}
              endAdornment={isLoading && <CircularProgress size={24} />}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
          {...field}
          onChange={(e, value) => {
            field.onChange(value);
            onChange?.(value);
          }}
        />
      )}
    />
  );
};

export default RfqQuantitiesInput;
