/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ArrowForwardRounded, Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";

import _, { sortBy } from "lodash";
import useGetOnHandForItem from "src/hooks/useGetOnHandForItem";

import Carousel from "@components/Carousel";
import { SectionTitle } from "@components/StyledComponents";
import { ResourceError } from "@features/errors";
import { clearItemPreview } from "@redux/slices/items/itemSlice";
import { CldImage } from "@services/cloudinary";
import { formatMoneyString } from "@utility/utilityFunctions";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { useItemQuery } from "../data";
import { useItemPrograms, variantHash } from "../helpers";

const Tag = tw.div`px-3 py-1 rounded bg-neutral-50 text-neutral-700 text-base border border-neutral-300 border-solid`;

const ProgramTags = ({ itemId }) => {
  const { data: programs, error } = useItemPrograms(itemId, { isDraft: false });
  if (error) return <ResourceError error={error} />;
  if (programs.length === 0) return null;
  return (
    <>
      <SectionTitle tw="mt-6">Programs</SectionTitle>
      <div tw="flex gap-2 flex-wrap">
        {programs.map((program) => (
          <Link key={program.id} to={`/programs/${program.id}`} target="_blank">
            <Tag>{program.name}</Tag>
          </Link>
        ))}
      </div>
    </>
  );
};

const ItemModal = () => {
  const dispatch = useDispatch();
  const roleIs = useRoleIs();
  const { itemPreviewId: itemId, itemPreviewFocusImageId } = useSelector(
    (state: any) => state.items
  );
  const userIsAdmin = roleIs(...permissions.admin);
  const isInternalRole = roleIs(...permissions.internalRoles);

  const open = Boolean(itemId);

  const { data: item, error, isLoading } = useItemQuery(itemId);

  const usesAllocations = useSelector(
    (state: any) => !!state.currentUser.organization.allocationLocation
  );

  const onHand = useGetOnHandForItem(item);

  const variantCategories = item
    ? _(item.variants)
        .flatMap((variant) =>
          variant.selectedVariantOptions.flatMap((vo) => vo.variantCategory)
        )
        .uniqBy("id")
        .sortBy("id")
        .value()
    : [];

  const activeVariants = item
    ? _(item.variants)
        .filter((variant) => variant.isActive)
        .map((variant) =>
          sortBy(variant.selectedVariantOptions, "variantCategory.id")
        )
        .sortBy((v) => variantHash({ selectedVariantOptions: v }))
        .value()
    : [];

  const handleClose = () => dispatch(clearItemPreview());

  const images = item?.images ?? [];
  const carouselStartIndex = itemPreviewFocusImageId
    ? images.findIndex((img) => img.id === itemPreviewFocusImageId)
    : 0;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        disableScrollLock
        fullWidth
        maxWidth="lg"
      >
        <IconButton
          tw="absolute top-1 right-1 z-10"
          onClick={() => {
            handleClose();
          }}
          size="large"
        >
          <Close color="secondary" />
        </IconButton>
        {isLoading && (
          <DialogContent tw="flex items-center justify-center min-h-[300px]">
            <CircularProgress />
          </DialogContent>
        )}
        {!isLoading && error && <ResourceError error={error} />}
        {item && (
          <DialogContent tw="overflow-hidden grid grid-cols-2 gap-4 p-0">
            <div tw="flex items-center justify-center p-4">
              <Carousel startIndex={carouselStartIndex}>
                {images.length > 0 ? (
                  images.map((img) => (
                    <div key={img.id}>
                      <CldImage image={img} size="large" alt={`${item.name}`} />
                    </div>
                  ))
                ) : (
                  <CldImage
                    src="https://res.cloudinary.com/brandhub/image/upload/v1685722984/prod/Brandhub/no-image-square_iefxuz.png"
                    alt="No Image"
                  />
                )}
              </Carousel>
            </div>
            <div tw="max-h-full overflow-y-auto text-base text-neutral-800">
              <Typography component="div" tw="max-w-prose mb-12 p-4">
                <div tw="grid grid-cols-3 space-x-2 -m-2 p-2 pt-8 sticky top-0 bg-white">
                  <div>
                    {item.sku}
                    <div tw="text-xs text-neutral-400">Sku</div>
                  </div>
                  <div>
                    {item.customerIdentifier ?? "---"}
                    <div tw="text-xs text-neutral-400">Product Identifier</div>
                  </div>
                  {userIsAdmin && (
                    <Button
                      component={Link}
                      to={`/admin/items/${item.id}`}
                      target="_blank"
                      tw="normal-case place-self-start self-end text-sm text-primary-600 hover:text-primary-800"
                      endIcon={<ArrowForwardRounded />}
                    >
                      Item Admin
                    </Button>
                  )}
                </div>

                <h1 tw="text-3xl mt-4">{item.name}</h1>
                {isInternalRole && (
                  <div tw="mt-3 flex items-center text-neutral-400">
                    <div tw=" text-xl font-bold tracking-wider text-neutral-600">
                      {formatMoneyString(item.price)}
                    </div>
                    &nbsp; / &nbsp;
                    <div tw="">
                      {item.orderType !== "on-demand"
                        ? `${onHand} on hand`
                        : "on demand"}
                    </div>
                  </div>
                )}
                <SectionTitle tw="mt-6">Description</SectionTitle>
                <div tw="text-neutral-600 whitespace-pre-line">
                  {item.description}
                </div>

                <SectionTitle tw="mt-6">Categories</SectionTitle>
                <div tw="flex flex-wrap gap-2">
                  {item.groups?.length > 0 &&
                    item.groups.map((group, i) => (
                      <Tag key={i}>
                        {group.name}
                        <div tw="text-xs text-neutral-500">
                          {group.groupCategory.name}
                        </div>
                      </Tag>
                    ))}
                </div>

                <SectionTitle tw="mt-6">Item info</SectionTitle>
                <div tw=" text-neutral-700">
                  <div>Unit of measurement: {item.unitOfMeasure}</div>
                  <div>Pack size: {item.packSize}</div>
                  <div>Order type: {item.orderType}</div>
                  {item.orderType !== "on-demand" && (
                    <>
                      {usesAllocations && userIsAdmin && (
                        <div>
                          On Hand (Global):{" "}
                          {_.sumBy(
                            item.variants,
                            (v) => v.cachedWarehouseQty ?? 0
                          )}
                        </div>
                      )}
                      {isInternalRole && (
                        <div>
                          On Hand{usesAllocations ? " (Allocation)" : ""}:{" "}
                          {onHand}
                        </div>
                      )}
                    </>
                  )}
                </div>
                {item.specification &&
                  Object.keys(item.specification).length > 0 && (
                    <>
                      <SectionTitle tw="mt-6">Specifications</SectionTitle>
                      <div tw="text-neutral-700">
                        {Object.keys(item.specification).map((k, i) => (
                          <div key={i}>
                            <b>{k}:</b>
                            {` ${item.specification[k]}`}
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                {variantCategories.length > 0 && (
                  <>
                    <SectionTitle tw="mt-6">Variants</SectionTitle>
                    <div tw="max-h-[300px] overflow-auto shadow-inner bg-neutral-50 rounded-md">
                      <table tw="w-full border-collapse  text-sm">
                        <thead tw="font-bold rounded overflow-hidden">
                          <tr>
                            {variantCategories.map((variantOption) => (
                              <td
                                key={variantOption.id}
                                tw="py-2 px-4 bg-gray-200"
                              >
                                {variantOption.name}
                              </td>
                            )) || null}
                          </tr>
                        </thead>
                        <tbody tw="divide-y">
                          {activeVariants.map((variantOptions, i) => (
                            <tr tw="hover:bg-neutral-100" key={i}>
                              {variantOptions.map(({ name }) => (
                                <td tw="py-2 px-4" key={`${i}-${name}`}>
                                  {name}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
                {isInternalRole && <ProgramTags itemId={itemId} />}
              </Typography>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default ItemModal;
